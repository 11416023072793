<script>
  import { formatPhone, formatPhoneHref } from '@qlean/web-core'
  import classNames from 'classnames/bind'
  import {
    Separator,
    Caption,
    Icon,
    GridContainer,
    GridColumn,
    Link,
  } from '@qlean/ui-kit-web-svelte'
  import { envConfig } from '../store'

  import css from './style.scss'

  import Logo from './_logos/Logo'
  import LogoWola from './_logos/LogoWola'
  import LogoWolaGF from './_logos/LogoWolaGF'
  import Telegram from './_supports/Telegram'
  import Phone from './_supports/Phone'
  import Profile from './Profile'
  import Region from './Region'

  import { typeWola, typeWolaGF } from '../../config'
  import { onMount } from 'svelte'

  const cN = classNames.bind(css)

  export let data
  export let handleRegionChange

  const {
    hideProfile,
    region,
    phone,
    regions,
    logoImage,
    content: { profile },
    withNavbar,
  } = data

  export let isSupportPage
  onMount(() => {
    if ($envConfig.supportPaths.includes(window.location.pathname)) {
      isSupportPage = true
    }
  })

</script>

<div class={cN('topbar', { 'topbar--without-navbar': !withNavbar })}>
  <GridContainer class={cN('topbar__grid-container')}>
    <GridColumn class={cN('topbar__grid-column')}>
      <Separator key={10} size={Separator.SIZES.MEDIUM} />
      <div class={cN('topbar__top-menu')}>
        <div class={cN('topbar__logo-wrap')}>
          {#if logoImage === typeWola}
            <a id="logo" href="/getwola">
              <LogoWola />
            </a>
          {:else if logoImage === typeWolaGF}
            <a id="logo" href="/getwola">
              <LogoWolaGF />
            </a>
          {:else}
            <a id="logo" href="/">
              <Logo />
            </a>
          {/if}
        </div>
        <div id="topbar__top-menu" class={cN('topbar__top-menu-slot')}>
          {#if region !== undefined && regions !== undefined && Object.keys(regions).length > 0}
            <div class={cN('topbar__top-menu-inner-slot')}>
              <Region items={regions} selectedCode={region} {handleRegionChange} />
            </div>
          {/if}

          {#if phone}
            <div class={cN('topbar__top-menu-inner-slot')}>
              <Link
                class={cN('topbar__header-phone-link')}
                href={formatPhoneHref(phone)}
                rank={Link.RANKS['1']}>
                <span class={cN('topbar__wrapper-phone-icon')}>
                  <Icon key={Icon.KEYS.MEDIUM} name={Icon.NAMES.handset} clickable={true} />
                </span>
                <Caption class={cN('topbar__phone-text')}>{formatPhone(phone)}</Caption>
              </Link>
            </div>
          {/if}

          {#if !hideProfile}
            <div class={cN('topbar__top-menu-inner-slot')}>
              <Profile items={profile} />
            </div>
          {/if}

          <div class={cN('topbar__top-menu-inner-slot')}>
            <Telegram />
          </div>

          {#if isSupportPage}
            <div class={cN('topbar__top-menu-inner-slot')}>
              <Phone />
            </div>
          {/if}
        </div>
      </div>
      <Separator key={10} size={Separator.SIZES.MEDIUM} />
    </GridColumn>
  </GridContainer>
</div>
