<script>
  import { Caption, Icon } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let items
  export let selectedCode
  export let handleRegionChange

  function onChange({ target: { value } }) {
    selectedCode = value

    handleRegionChange(selectedCode)
  }

  const disabled = Object.keys(items).length === 1
</script>

<div class={cN('region')}>
  <Icon class={cN('region__icon')} key={Icon.KEYS.SMALL} name={Icon.NAMES.arrowGeo} />
  <Caption class={cN('region__text')}>{items[selectedCode]}</Caption>
  <!-- svelte-ignore a11y-no-onchange -->
  <select class={cN('region__select')} bind:value={selectedCode} {disabled} on:change={onChange}>
    {#each Object.entries(items) as [code, name]}
      <option value={code}>{name}</option>
    {/each}
  </select>
</div>
