<script>
  import { Bus, USER_LOGOUT_REQUEST } from '@qlean/web-core'
  import { ActionText, Link } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let items

  const onLogout = (evt) => {
    evt.preventDefault()
    Bus.emit(USER_LOGOUT_REQUEST)
  }
</script>

<div class={cN('header-dropdown')}>
  <slot />
  <div class={cN('header-dropdown__content-container')}>
    <div class={cN('header-dropdown__content')}>
      {#each items as item}
        {#if item.name === 'logout'}
          <Link
            on:click={onLogout}
            href="#"
            rank={Link.RANKS[3]}
            class={cN('header-dropdown__content-menu-item')}>
            <ActionText>{item.title}</ActionText>
          </Link>
        {:else}
          <Link
            href={item.href}
            rank={Link.RANKS[3]}
            class={cN('header-dropdown__content-menu-item')}>
            <ActionText>{item.title}</ActionText>
          </Link>
        {/if}
      {/each}
    </div>
  </div>
</div>
