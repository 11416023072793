import { writable } from 'svelte/store'

export const isLogIn = writable(false)

export const envConfig = writable({
  ssoUrl: null,
  ssoClientId: null,
  tgLink: null,
  supportPhone: null,
})
