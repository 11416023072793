<script>
  import { formatPhoneHref } from '@qlean/web-core'
  import { envConfig } from '../../../store'
  import { Text, Link } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'
  import telephoneIcon from './assets/telephone.svg'

  import css from './style.scss'

  const cN = classNames.bind(css)

  const phone = $envConfig.supportPhone

</script>

<Link href={formatPhoneHref(phone)} rank={Link.RANKS[1]}>
  <Text class={cN('support-phone__text')}>{phone}</Text>
  <div class={cN('support-phone__icon')}>
    {@html telephoneIcon}
  </div>
</Link>
