<script>
  import { onMount } from 'svelte'
  import { Bus, REGION_CHANGE, USER_LOGOUT_SUCCESS } from '@qlean/web-core'
  import { Icon } from '@qlean/ui-kit-web-svelte'

  import { isLogIn, envConfig } from './store'
  import Topbar from './Topbar'

  export let content
  export let regions = undefined
  export let hideProfile
  export let isLoggedIn = false
  export let region = undefined
  export let phone
  export let logoImage
  export let withNavbar = true
  export let envs

  isLogIn.set(isLoggedIn)
  envConfig.set(envs)

  let headerData = {
    hideProfile,
    region,
    phone,
    regions,
    content,
    logoImage,
    withNavbar,
  }

  const handleRegionChange = (payload) => {
    Bus.emit(REGION_CHANGE, payload)
  }

  $: handleLogout = () => {
    isLogIn.set(false)
  }

  onMount(() => {
    const unSub = Bus.on(USER_LOGOUT_SUCCESS, handleLogout)

    return () => {
      Bus.off(USER_LOGOUT_SUCCESS, unSub)
    }
  })
</script>

{@html Icon.spriteContent}
<Topbar data={headerData} {handleRegionChange} />
