<script>
  import classNames from 'classnames/bind'
  import { onMount } from 'svelte'
  import { Caption, Icon, Link } from '@qlean/ui-kit-web-svelte'

  import css from './style.scss'

  import Dropdown from '../Dropdown'

  import { isLogIn, envConfig } from '../../store'

  const cN = classNames.bind(css)

  export let items
  export let selectedItem

  let url = $envConfig.ssoUrl

  onMount(() => {
    const clientIdParam = $envConfig.ssoClientId ? `clientId=${$envConfig.ssoClientId}` : undefined
    const redirectUrlParam = `redirectUrl=${encodeURIComponent(window.location.href)}`

    const params = [clientIdParam, redirectUrlParam].filter((prm) => prm !== undefined).join('&')

    url = `${url}?${params}`
  })
</script>

{#if $isLogIn}
  <Dropdown {items} {selectedItem}>
    <span class={cN('topbar-profile')}>
      <Icon
        class={cN('topbar-profile__icon')}
        key={Icon.KEYS.MEDIUM}
        name={Icon.NAMES.faceMan}
        clickable={true} />
      <Caption class={cN('topbar-profile__text', 'topbar-profile__text--profile')}>Профиль</Caption>
    </span>
  </Dropdown>
{:else}
  <Link class={cN('topbar-profile')} href={url} rank={Link.RANKS['1']}>
    <Icon
      class={cN('topbar-profile__icon')}
      key={Icon.KEYS.MEDIUM}
      name={Icon.NAMES.enter}
      clickable={true} />
    <Caption class={cN('topbar-profile__text')}>Войти</Caption>
  </Link>
{/if}
